import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { baseApiUrl } from "../Utilities/constants";

export interface Application {
    service_key: string;
    account_id: string;
    name: string;
    platform: string;
    steam_url: string | null;
    demo_date: string | null;
    launch_date: string | null;
    icon_url: string | null;
    created_at: number;
}

export interface OverviewData {
    max_concurrent_sessions: number;
    current_concurrent_sessions: number;
    unique_session_count: number;
    unique_user_count: number;
    peak_daily_active_users: number;
    median_session_time: number;
    median_time_per_user_per_day: number;
    median_total_time_per_user: number;
    total_time_spent_all_users: number;
    per_day_metrics: [{
        session_date: string;
        unique_users: number;
        unique_sessions: number;
        median_session_length: number;
        new_users: number;
        retention_days: [{
            day_number: number;
            retained_users: number;
        }] | null;
    }] | null;
    retention_days: [{
        day_number: number;
        total_new_users: number;
        retained_users: number;
    }] | null;
    date_range: {
        start_date: number;
        end_date: number;
    } | null;
}

export interface IAppState {
    applications: [] | null;
    selectedApplication: Application | null;
    loadApplications?: any;
    createApplication?: (
        name: string, platform: string, steam_url: string | null, demo_date: string | null,
        launch_date: string | null, icon_url: string | null
    ) => void;
    deleteApplication?: any;
    selectApplication?: any;

    storedAnalyticsData: { [service_key: string]: [] } | null;
    getAllAnalyticsData?: (service_key: string, start_date?: number, end_date?: number) => Promise<[] | null>;
    getAllAnalyticsDataForRange?: (service_key: string, start_date?: number, end_date?: number) => Promise<[] | null>;

    storedOverviewData: { [service_key: string]: OverviewData } | null;
    getOverviewData?: (service_key: string, start_date?: number, end_date?: number) => Promise<OverviewData | null>;
    getOverviewDataForRange?: (service_key: string, start_date?: number, end_date?: number) => Promise<[] | null>;

    getLiveAnalyticsData?: (service_key: string) => Promise<[] | null>;

    addAccountToApplication?: (service_key: string, account_id: string) => void;
}

const defaultState: IAppState = {
    applications: null,
    selectedApplication: null,
    storedAnalyticsData: null,
    storedOverviewData: null,
};

type Props = {
    children?: React.ReactNode;
};

export const AppStateContext = React.createContext(defaultState);

const appStateStorageKey = "app_state";

const AppStateProvider = ({ children }: Props) => {
    const authContext = useContext(AuthContext)
    const [appState, setAppState] = useState(defaultState);

    useEffect(() => {
        if (!authContext.isLoggedIn() || !authContext.user?.token) {
            return;
        }

        async function getStoredAppState() {
            try {
                //attempt to get state from local storage
                let localStorageAppState = localStorage.getItem(appStateStorageKey);
                if (localStorageAppState) {
                    let parsedAppState = JSON.parse(localStorageAppState);
                    if (parsedAppState) {
                        setAppState(parsedAppState);
                        if (!parsedAppState.applications) {
                            await loadAllApplications();
                        }
                        if (!parsedAppState.storedOverviewData && parsedAppState.selectedApplication) {
                            if (!(parsedAppState.selectedApplication.service_key in parsedAppState.storedOverviewData)) {
                                //await getAllAnalyticsData(parsedAppState.selectedApplication.service_key);
                                await getOverviewData(parsedAppState.selectedApplication.service_key);
                            }
                        }
                        return;
                    }
                }

                //if no state in local storage, retrieve all relevant values from server
                await loadAllApplications();
            } catch (e) {
                setAppState(defaultState);
            }
        }

        getStoredAppState().then();
    }, [authContext.isLoggedIn(), authContext.user?.token]);

    const loadAllApplications = async () => {
        let apps = [];
        try {
            const response = await axios.get(`${baseApiUrl}/dashboard/get-all-applications`, {
                headers: {
                    'Authorization': `Bearer ${authContext.user?.token}`
                }
            });

            apps = response.data.applications || [];
        } catch (e) {
            apps = [];
            console.log(e);
        } finally {
            const newAppState = { ...appState, applications: apps };
            localStorage.setItem(appStateStorageKey, JSON.stringify(newAppState));
            setAppState(newAppState);
        }
    }

    const convertToUnixTimestamp = (dateString: string) => {
        try {
            let date;

            if (/^\d{4}$/.test(dateString)) {
                // The dateString is just a year, append "-01-01" to make it a full date
                date = new Date(dateString + "-01-01");
            } else if (/^\d{1,2}\/\d{4}$/.test(dateString)) {
                // The dateString is a month and year, append "-01" to make it a full date
                let [month, year] = dateString.split('/');
                // Ensure month is two digits
                if (month.length === 1) {
                    month = '0' + month;
                }
                date = new Date(year + "-" + month + "-01");
            } else {
                // The dateString is a full date
                date = new Date(dateString);
            }

            // Convert to Unix timestamp (milliseconds since January 1, 1970)
            let unixTimestamp = date.getTime();

            return unixTimestamp;
        } catch (e) {
            console.error(e)
            return 0
        }
    }

    const createNewApplication = async (
        name: string, platform: string, steam_url: string | null = null, demo_date: string | null = null,
        launch_date: string | null = null, icon_url: string | null = null
    ) => {
        try {
            let response = await axios.post(`${baseApiUrl}/dashboard/create-application`,
                {
                    name: name,
                    platform: platform,
                    steam_url: steam_url,
                    demo_date: convertToUnixTimestamp(demo_date || ''),
                    launch_date: convertToUnixTimestamp(launch_date || ''),
                    icon_url: icon_url
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authContext.user?.token}`
                    }
                });

            if (!response.data) {
                console.log('error creating application');
                return;
            }

            if (response.data === 'account_not_verified') {
                console.log('account not verified');
                return;
            }

            if (!appState.applications) {
                loadAllApplications();
                return;
            }

            let newApp: Application = {
                service_key: response.data as string,
                account_id: authContext.user?.account_id || '',
                name: name,
                platform: platform,
                steam_url: steam_url,
                demo_date: demo_date,
                launch_date: launch_date,
                icon_url: icon_url,
                created_at: Date.now()
            }

            let newApplications = [...appState.applications, newApp];
            const newAppState: IAppState = {
                ...appState,
                applications: newApplications as [],
                selectedApplication: newApp
            };
            localStorage.setItem(appStateStorageKey, JSON.stringify(newAppState));
            setAppState(newAppState);
        } catch (e) {
            console.log(e);
        }
    }

    const deleteApplication = async (application: Application) => {
        try {
            let response = await axios.post(`${baseApiUrl}/dashboard/delete-application`,
                {
                    service_key: application.service_key
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authContext.user?.token}`
                    }
                });

            if (!response) {
                console.log('error deleting application');
                return;
            }

            if (response.data && response.data === 'account_not_verified') {
                console.log('account not verified');
                return;
            }

            //check if successful status
            if (response.status >= 300 || response.status < 200) {
                console.log('error deleting application');
                return;
            }

            if (!appState.applications) {
                loadAllApplications();
                return;
            }

            const filteredApplications = appState.applications.filter((app: Application) => {
                return app.service_key !== application.service_key;
            }) as [];

            const newAppState: IAppState = {
                ...appState,
                applications: filteredApplications,
                selectedApplication: appState.selectedApplication?.service_key === application.service_key ? null : appState.selectedApplication
            };
            localStorage.setItem(appStateStorageKey, JSON.stringify(newAppState));
            setAppState(newAppState);
        } catch (e) {
            console.log(e);
        }
    }

    const selectApplication = (application: Application) => {
        const newAppState = { ...appState, applications: appState.applications, selectedApplication: application };
        localStorage.setItem(appStateStorageKey, JSON.stringify(newAppState));
        setAppState(newAppState);
    }

    const getAllAnalyticsDataForRange = async (service_key: string, start_date?: number, end_date?: number) => {
        if (!service_key) {
            return;
        }

        if (!start_date) {
            start_date = 0;
        }

        if (!end_date) {
            end_date = Date.now() * 10_000_000;
        }

        start_date = start_date * 10_000_000;
        end_date = end_date * 10_000_000;

        console.log('getting analytics data for', service_key, start_date, end_date);

        let analyticsLogs = null;
        try {
            const response = await axios.post(`${baseApiUrl}/dashboard/get-raw-data-logs`,
                {
                    service_key: service_key,
                    start_date: start_date,
                    end_date: end_date
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authContext.user?.token}`
                    }
                });

            analyticsLogs = response.data.logs || [];
        } catch (e) {
            analyticsLogs = null;
            console.log(e);
        } finally {
            return analyticsLogs;
        }
    }

    const getAllAnalyticsData = async (service_key: string, start_date?: number, end_date?: number) => {
        let analyticsLogs = null;
        try {
            analyticsLogs = await getAllAnalyticsDataForRange(service_key, start_date, end_date);
        } catch (e) {
            analyticsLogs = null;
            console.log(e);
        } finally {
            if (!analyticsLogs) {
                return null;
            }

            //get stored data
            let newAppState = { ...appState };
            let storedData = localStorage.getItem(appStateStorageKey);
            if (storedData) {
                let parsedData = JSON.parse(storedData);
                if (parsedData.storedAnalyticsData) {
                    parsedData.storedAnalyticsData[service_key] = analyticsLogs;
                    newAppState = { ...parsedData };
                }
            }

            newAppState = { ...newAppState, storedAnalyticsData: { [service_key]: analyticsLogs } };
            localStorage.setItem(appStateStorageKey, JSON.stringify(newAppState));
            setAppState(newAppState);

            return analyticsLogs;
        }
    }

    const getOverviewDataForRange = async (service_key: string, start_date?: number, end_date?: number) => {
        if (!service_key) {
            return;
        }

        if (!start_date) {
            start_date = 0;
        }

        if (!end_date) {
            end_date = Date.now();
        }

        console.log('getting analytics data for', service_key, start_date, end_date);

        let overviewData = null;
        try {
            const response = await axios.post(`${baseApiUrl}/dashboard/get-overview-data`,
                {
                    service_key: service_key,
                    start_date: start_date,
                    end_date: end_date
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authContext.user?.token}`
                    }
                });

                overviewData = response.data || {};
        } catch (e) {
            overviewData = null;
            console.log(e);
        } finally {
            return overviewData;
        }
    }

    const getOverviewData = async (service_key: string, start_date?: number, end_date?: number) => {
        let overviewData = null;
        try {
            overviewData = await getOverviewDataForRange(service_key, start_date, end_date) as OverviewData;
        } catch (e) {
            overviewData = null;
            console.log(e);
        } finally {
            if (!overviewData) {
                return null;
            }

            //get stored data
            let newAppState = { ...appState };
            let storedData = localStorage.getItem(appStateStorageKey);
            if (storedData) {
                let parsedData = JSON.parse(storedData);
                if (parsedData.storedOverviewData) {
                    parsedData.storedOverviewData[service_key] = overviewData as OverviewData;
                    newAppState = { ...parsedData };
                }
            }

            overviewData.date_range = {
                start_date: start_date || 0,
                end_date: end_date || Date.now()
            };

            //combine retention data per day
            let totalNewUsers = 0;
            if (overviewData.per_day_metrics) {
                overviewData.per_day_metrics.forEach((day) => {
                    totalNewUsers += day.new_users;
                });

                let retentionDays = [];
                for (let i = 1; i <= 30; i++) {
                    let retainedUsers = 0;
                    overviewData.per_day_metrics.forEach((day) => {
                        if (day.retention_days) {
                            day.retention_days.forEach((retentionDay) => {
                                if (retentionDay.day_number === i) {
                                    retainedUsers += retentionDay.retained_users;
                                }
                            });
                        }
                    });

                    retentionDays.push({
                        day_number: i,
                        total_new_users: totalNewUsers,
                        retained_users: retainedUsers
                    });
                }

                overviewData.retention_days = retentionDays as any;
            }

            console.log('overview data', overviewData);

            newAppState = { ...newAppState, storedOverviewData: { [service_key]: overviewData as OverviewData } };
            localStorage.setItem(appStateStorageKey, JSON.stringify(newAppState));
            setAppState(newAppState);
            
            return overviewData;
        }
    }

    const getLiveAnalyticsData = async (service_key: string) => {
        let analyticsLogs = null;
        try {
            const response = await axios.post(`${baseApiUrl}/dashboard/get-latest-logs`,
                {
                    service_key: service_key
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authContext.user?.token}`
                    }
                });

            analyticsLogs = response.data.logs || [];
        } catch (e) {
            console.log(e);
        } finally {
            return analyticsLogs;
        }
    }

    const addAccountToApplication = async (service_key: string, account_id: string) => {
        try {
            if (!service_key || !account_id) {
                return;
            }

            let response = await axios.post(`${baseApiUrl}/dashboard/add-account-to-application`,
                {
                    service_key: service_key,
                    account_id: account_id
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authContext.user?.token}`
                    }
                });

            if (response.status >= 300 || response.status < 200) {
                console.log('error adding account to application');
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    const state: IAppState = {
        applications: appState.applications,
        selectedApplication: appState.selectedApplication,
        loadApplications: loadAllApplications,
        createApplication: createNewApplication,
        deleteApplication: deleteApplication,
        selectApplication: selectApplication,

        storedAnalyticsData: appState.storedAnalyticsData,
        getAllAnalyticsData: getAllAnalyticsData,
        getAllAnalyticsDataForRange: getAllAnalyticsDataForRange,

        storedOverviewData: appState.storedOverviewData,
        getOverviewData: getOverviewData,
        getOverviewDataForRange: getOverviewDataForRange,

        getLiveAnalyticsData: getLiveAnalyticsData,

        addAccountToApplication: addAccountToApplication
    };

    if (!authContext.isLoggedIn()) {
        return <AppStateContext.Provider value={defaultState}>{children}</AppStateContext.Provider>;
    }

    return <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>;
};
export default AppStateProvider;
