import { Button, ButtonGroup, Card, Divider, HStack, Input, Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'

import { AppStateContext } from '../Contexts/AppStateContext'

const ApplicationSettings = () => {
    const appState = useContext(AppStateContext)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [accountID, setAccountID] = useState('')
    const [isAddAccountPopoverOpen, setIsAddAccountPopoverOpen] = useState(false)

    return (
        <HStack>
            <VStack p={3} w='100%'>
                <Text fontWeight={500} w='100%' textAlign='left'>Application Settings</Text>
                <Divider />
                <VStack alignSelf='flex-start' mt={2} p={0}>
                    <Card w='100%' p={3}>
                        <Text fontWeight={500} w='100%' textAlign='left'>{appState.selectedApplication?.name}</Text>
                    </ Card>
                    <Card w='100%' p={3}>
                        <Text fontWeight={500} w='100%' textAlign='left'>{appState.selectedApplication?.platform}</Text>
                    </ Card>
                    <Card w='100%' p={2}>
                        <HStack p={1}>
                            <Text fontWeight={500} w='100%' textAlign='left'>
                                {appState.selectedApplication?.service_key}
                            </Text>
                            <Button variant='outline' onClick={() => navigator.clipboard.writeText(appState.selectedApplication?.service_key || '')}>
                                Copy
                            </Button>
                        </HStack>
                    </ Card>
                    <Divider mt={1} mb={1} />
                    <Card w='100%' p={4} variant='outline'>
                        <Text fontWeight={500} w='100%' textAlign='left'>Add Account to Application</Text>
                        <Divider mt={1} mb={2} />
                        <Input placeholder='Account ID' onChange={(e) => setAccountID(e.target.value)} />
                        <Popover isOpen={isAddAccountPopoverOpen}>
                            <PopoverTrigger>
                                <Button mt={2} w='100%' onClick={() => {
                                    setIsAddAccountPopoverOpen(true)
                                    setIsPopoverOpen(false)
                                }}>
                                    Add Account
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
                                <PopoverBody>
                                    <VStack p={3}>
                                        <Text fontWeight={500} w='100%' textAlign='left'>
                                            {`Are you sure you want to add account [${accountID}] to ${appState.selectedApplication?.name || 'unknown'}?`}
                                        </Text>
                                    </VStack>
                                </PopoverBody>
                                <PopoverFooter display='flex' justifyContent='flex-end'>
                                    <ButtonGroup size='sm'>
                                        <Button variant='outline' w='100%' onClick={() => setIsAddAccountPopoverOpen(false)}>Cancel</Button>
                                        <Button colorScheme='green' w='100%' onClick={() => {
                                            appState.addAccountToApplication && appState.addAccountToApplication(appState.selectedApplication?.service_key || "", accountID)
                                            setIsAddAccountPopoverOpen(false)
                                            setAccountID('')
                                        }}>Add</Button>
                                    </ButtonGroup>
                                </PopoverFooter>
                            </PopoverContent>
                        </Popover>
                    </ Card>
                    <Divider mt={1} mb={1} />
                    <Popover isOpen={isPopoverOpen}>
                        <PopoverTrigger>
                            <Button colorScheme='red' w='100%' onClick={() => {
                                setIsPopoverOpen(true)
                                setIsAddAccountPopoverOpen(false)
                            }}>
                                Delete Application
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
                            <PopoverBody>
                                <VStack p={3}>
                                    <Text fontWeight={500} w='100%' textAlign='left'>
                                        {`Are you sure you want to delete ${appState.selectedApplication?.name || 'unknown'}?`}
                                    </Text>
                                </VStack>
                            </PopoverBody>
                            <PopoverFooter display='flex' justifyContent='flex-end'>
                                <ButtonGroup size='sm'>
                                    <Button variant='outline' w='100%' onClick={() => setIsPopoverOpen(false)}>Cancel</Button>
                                    <Button colorScheme='red' w='100%' onClick={() => {
                                        appState.deleteApplication(appState.selectedApplication)
                                        setIsPopoverOpen(false)
                                    }}>Delete</Button>
                                </ButtonGroup>
                            </PopoverFooter>
                        </PopoverContent>
                    </Popover>
                </ VStack>
            </VStack>
        </HStack>
    )
}

export default ApplicationSettings