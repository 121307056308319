import { Button, Card, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import ApplicationSearchMenu from './ApplicationSearchMenu';

import { IoMdSettings, IoMdAnalytics } from "react-icons/io";
import { BsFillLightningFill, BsLightningFill } from "react-icons/bs";

import { useNavigate } from 'react-router-dom';
import { AppStateContext } from '../Contexts/AppStateContext';

interface DashboardLandingProps {
  mainBodyComponent: any
}

const DashboardLanding = ({ mainBodyComponent }: DashboardLandingProps) => {
  const appState = useContext(AppStateContext)
  const navigate = useNavigate()

  useEffect(() => {

  }, [appState])

  const NavMenuButton = (props: any) => {
    return (
      <Button width='100%' variant='ghost' onClick={props.onClick?.bind(this)}>
        {props.icon}
        <Text ml={3} width='100%' textAlign='left' fontSize={16} fontWeight={500}>
          {props.text}
        </Text>
      </Button>
    )
  }

  return (
    <div>
      <HStack m={3} gap={3} align='flex-start'>
        <Card p={1} variant='outline' shadow='md' minW={300}>
          <VStack width='100%'>
            <ApplicationSearchMenu />
            <Divider />
            <NavMenuButton icon={<IoMdAnalytics size={20} />} text="Overview" onClick={() => navigate('/dashboard')} />
            <NavMenuButton icon={<BsLightningFill size={20} />} text="Live Activity Feed" onClick={() => navigate('/dashboard/live-activity-feed')} />
            <NavMenuButton icon={<IoMdSettings size={20} />} text="Settings" onClick={() => navigate('/dashboard/settings')} />
          </VStack>
        </Card>
        <Card p={1} variant='outline' shadow='md' height='100%' width='100%'>
          {mainBodyComponent}
        </Card>
        {
          /*
        <Card p={1} variant='outline' shadow='md' minW={300}>
          <VStack width='100%'>
            <Text pl={3} pt={1} width='100%' textAlign='left' fontSize={16} fontWeight={500}>
              Side Stuff
            </Text>
            <Divider />
            <NavMenuButton icon={<IoMdSettings size={20} />} text="Account Settings?" />
            <NavMenuButton icon={<IoMdSettings size={20} />} text="Docs Maybe?" />
            <NavMenuButton icon={<IoMdSettings size={20} />} text="Link to SDK?" />
          </VStack>
        </Card>
        */
        }
      </HStack>
    </div>
  )
}

export default DashboardLanding