import { Box, Button, Card, Center, Checkbox, Divider, Flex, FocusLock, HStack, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverBody, PopoverContent, PopoverTrigger, SimpleGrid, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, Wrap } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'

import { RangeDatepicker } from '../Components/chakra-dayzed-datepicker/src/index'

import { AppStateContext } from '../Contexts/AppStateContext'
import { processData, ProcessedData } from '../Utilities/dataProcessing'

interface ReportBuilderProps {
    suppliedAnalyticsData: ProcessedData | undefined
}

const ReportBuilder = ({ suppliedAnalyticsData }: ReportBuilderProps) => {
    const appState = useContext(AppStateContext)
    const eventNames = Array.from(suppliedAnalyticsData?.eventData.eventNames || new Set<string>) || []

    const [analyticsData, setAnalyticsData] = useState<ProcessedData>()
    const [range, setRange] = useState<Date[]>([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()])
    const [reportFilterMenuState, setReportFilterMenuState] = useState('Event')
    const [filterPopOverOpen, setFilterPopOverOpen] = useState(false)

    const [eventAndParamEvent, setEventAndParamEvent] = useState(eventNames[0] || '')
    const [eventAndParamParam, setEventAndParamParam] = useState('')
    const [eventAndParamPopOverOpen, setEventAndParamPopOverOpen] = useState(false)

    const [fetchingData, setFetchingData] = useState(false)
    const [lastSuccessfulFetchRange, setLastSuccessfulFetchRange] = useState<Date[]>([new Date(), new Date()])

    useEffect(() => {
        
    }, [range])

    const dateRangeIsTheSame = (a: Date[], b: Date[]) => {
        return a[0].getTime() === b[0].getTime() && a[1].getTime() === b[1].getTime()
    }

    const fetchData = async () => {
        if (fetchingData) {
            console.log('already fetching data')
            return
        }

        if (dateRangeIsTheSame(range, lastSuccessfulFetchRange)) {
            console.log('already fetched this range')
            return
        }

        setFetchingData(true)
        try {
            await Promise.resolve(appState.getAllAnalyticsDataForRange?.(
                appState.selectedApplication?.service_key || "",
                range[0]?.getTime(),
                range[1]?.getTime() + 86_400_000 // add a day to the end date
            )).then((result) => {
                if (result) {
                    setLastSuccessfulFetchRange(range)
                }

                setAnalyticsData(processData(result || []))
                setFetchingData(false)
            })
        } catch (e) {
            console.log(e)
        }
    }

    const pressRunButton = () => {
        fetchData()
    }

    interface FilterState {
        ['Event']: string[],
        ['App Version']: string[],
    }
    const defaultFilterState = {
        ['Event']: [],
        ['App Version']: [],
    }
    const [reportFilterState, setReportFilterState] = useState<FilterState>(defaultFilterState)

    useEffect(() => {
        console.log(reportFilterState)
    }, [reportFilterState])

    const getFilterButtons = () => {
        switch (reportFilterMenuState) {
            case 'Event':
                return (
                    Array.from(suppliedAnalyticsData?.eventData.eventNames || []).map((key: string) => {
                        return (
                            <Checkbox isChecked={
                                reportFilterState['Event'].includes(key)
                            } key={key} onChange={(e) => {
                                if (e.target.checked) {
                                    setReportFilterState({
                                        ...reportFilterState,
                                        'Event': [...reportFilterState['Event'], key]
                                    })
                                } else {
                                    setReportFilterState({
                                        ...reportFilterState,
                                        'Event': reportFilterState['Event'].filter((value) => value !== key)
                                    })
                                }
                            }}>
                                {key}
                            </Checkbox>
                        )
                    })
                )
            case 'App Version':
                return (
                    null
                )
            default:
                return (
                    null
                )
        }
    }

    const getFilteredTableData = () => {
        if (fetchingData) {
            return (
                <Center p={6}>
                    <Spinner />
                </Center>
            )
        }

        if (!analyticsData) {
            return (
                <Center p={6}>
                    <Text>
                        No data to display. Please select a range, set up a filter, and press run.
                    </Text>
                </Center>
            )
        }

        console.log(analyticsData)

        const filteredRawEventsLogs = analyticsData.eventData.events.filter((event) => {
            if (event.payload.name === eventAndParamEvent) {
                if (event.payload[eventAndParamParam]) {
                    return true
                }
            }
        });

        //get number of users and events for each event per each unique param value, i.e. {eventName: {paramValue: {eventCount: 0, userCount: 0}}}
        const filteredData = new Map<string, Map<string, Map<string, number>>>();
        const eventParamUserMap = new Map<string, Map<string, Set<string>>>();
        filteredRawEventsLogs.forEach((event) => {
            const eventName = event.payload.name;
            const eventParamValue = event.payload[eventAndParamParam];
            if (!filteredData.has(eventName)) {
                filteredData.set(eventName, new Map<string, Map<string, number>>());
            }
            const eventMap = filteredData.get(eventName);
            if (!eventMap?.has(eventParamValue)) {
                eventMap?.set(eventParamValue, new Map<string, number>());
            }
            const eventParamMap = eventMap?.get(eventParamValue);
            eventParamMap?.set('eventCount', (eventParamMap?.get('eventCount') || 0) + 1);

            if (!eventParamUserMap.has(eventName)) {
                eventParamUserMap.set(eventName, new Map<string, Set<string>>());
            }
            const userMap = eventParamUserMap.get(eventName);
            if (!userMap?.has(eventParamValue)) {
                userMap?.set(eventParamValue, new Set<string>());
            }
            userMap?.get(eventParamValue)?.add(event.user_id);

            eventParamMap?.set('userCount', userMap?.get(eventParamValue)?.size || 0);
        });

        const keys = Array.from(filteredData?.keys() || []);
        keys.sort((a, b) => {
            //sort by event count
            const aCount = Array.from(filteredData?.get(a)?.values() || []).reduce((acc, val) => acc + (val?.get('eventCount') || 0), 0);
            const bCount = Array.from(filteredData?.get(b)?.values() || []).reduce((acc, val) => acc + (val?.get('eventCount') || 0), 0);
            return bCount - aCount;
        });

        const paramKeys = Array.from(filteredData?.get(eventAndParamEvent)?.keys() || []);
        paramKeys.sort((a, b) => {
            //sort by event count
            const aCount = filteredData?.get(eventAndParamEvent)?.get(a)?.get('eventCount') || 0;
            const bCount = filteredData?.get(eventAndParamEvent)?.get(b)?.get('eventCount') || 0;
            return bCount - aCount;
        });

        return (
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Event</Th>
                        <Th>{eventAndParamParam}</Th>
                        <Th>Count</Th>
                        <Th>Users</Th>
                        <Th>Per User</Th>
                        <Th>Engagement</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        keys.map((eventName: string) => {
                            return paramKeys.map((paramValue: string) => {
                                const eventCount = filteredData?.get(eventName)?.get(paramValue)?.get('eventCount') || 0;
                                const userCount = filteredData?.get(eventName)?.get(paramValue)?.get('userCount') || 0;
                                return (
                                    <Tr key={eventName + paramValue}>
                                        <Td>{eventName}</Td>
                                        <Td>{paramValue}</Td>
                                        <Td>{eventCount}</Td>
                                        <Td>{userCount}</Td>
                                        <Td>{(eventCount / userCount).toFixed(2)}</Td>
                                        <Td>{((userCount / analyticsData?.uniqueUsers) * 100).toFixed(2)}%</Td>
                                    </Tr>
                                )
                            })
                        })
                    }
                </Tbody>
            </Table>
        )
    }

    const filterPopOver = () => {
        return (
            <Popover
                placement='top-start'
                offset={[0, 5]}
                isOpen={filterPopOverOpen}
            >
                <PopoverTrigger>
                    <Button
                        variant={'outline'}
                        marginRight="5px"
                        zIndex={1}
                        type="button"
                        padding={'8px'}
                        onClick={() => {
                            setEventAndParamPopOverOpen(false)
                            setFilterPopOverOpen(!filterPopOverOpen)
                        }}
                    >
                        Filters
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    width="100%"
                >
                    <PopoverBody shadow="lg" p={4}>
                        <FocusLock>
                            <Flex height="100%">
                                <Flex
                                    gap={2}
                                    direction='column'
                                    height="100%"
                                    mr={2}
                                    padding="0.5rem 0.75rem"
                                    justifyContent='flex-start'
                                    justify='flex-start'
                                    alignContent='flex-start'
                                >
                                    <Button onClick={() => {
                                        setReportFilterMenuState('Event')
                                    }}>
                                        Event
                                    </Button>
                                </Flex>
                                <Flex direction='column'>
                                    <Card p={4}>
                                        <Text mb={2}>
                                            {reportFilterMenuState}
                                        </Text>
                                        <Divider />
                                        <SimpleGrid columns={4} spacing={4} p={3}>
                                            {getFilterButtons()}
                                        </SimpleGrid>
                                    </Card>
                                </Flex>
                            </Flex>
                            <Divider mb={2} mt={8} />
                            <Flex gap={2} direction='row' justifyContent='space-between'>
                                <Button onClick={() => {
                                    setReportFilterState(defaultFilterState)
                                }}>
                                    Clear
                                </Button>
                                <Button color='green' onClick={() => {
                                    setFilterPopOverOpen(false)
                                }}>
                                    Accept
                                </Button>
                            </Flex>
                        </FocusLock>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    }

    const eventAndParamPopOver = () => {
        return (
            <Popover
                placement='top-start'
                offset={[0, 5]}
                isOpen={eventAndParamPopOverOpen}
            >
                <PopoverTrigger>
                    <Button
                        variant={'outline'}
                        marginRight="5px"
                        zIndex={1}
                        type="button"
                        padding={'8px'}
                        onClick={() => {
                            setFilterPopOverOpen(false)
                            setEventAndParamPopOverOpen(!eventAndParamPopOverOpen)
                        }}
                    >
                        Event & Params
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    width="100%"
                >
                    <PopoverBody shadow="lg" p={4}>
                        <FocusLock>
                            <Flex height="100%">
                                <Flex direction='column'>
                                    <Card p={4}>
                                        <Menu>
                                            <Flex align='center' gap={2} direction='row' justify='space-between'>
                                                <Text mr={4}>
                                                    Event:
                                                </Text>
                                                <MenuButton>
                                                    <Button>
                                                        {eventAndParamEvent}
                                                    </Button>
                                                </MenuButton>
                                            </Flex>
                                            <MenuList>
                                                {
                                                    Array.from(suppliedAnalyticsData?.eventData.eventNames || []).map((key: string) => {
                                                        return (
                                                            <MenuItem key={key} onClick={() => {
                                                                setEventAndParamEvent(key)
                                                            }}>
                                                                {key}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </MenuList>
                                        </Menu>
                                    </Card>
                                    <Card p={4} mt={4}>
                                        <Menu>
                                            <Flex align='center' gap={2} direction='row' justify='space-between'>
                                                <Text mr={4}>
                                                    Parameter:
                                                </Text>
                                                <MenuButton>
                                                    <Button>
                                                        {eventAndParamParam}
                                                    </Button>
                                                </MenuButton>
                                            </Flex>
                                            <MenuList>
                                                {
                                                    Array.from(suppliedAnalyticsData?.eventData.eventParamNames || []).map((key: string) => {
                                                        return (
                                                            <MenuItem key={key} onClick={() => {
                                                                setEventAndParamParam(key)
                                                            }}>
                                                                {key}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </MenuList>
                                        </Menu>
                                    </Card>
                                </Flex>
                            </Flex>
                            <Divider mb={2} mt={8} />
                            <Flex gap={2} direction='row' justifyContent='space-between'>
                                <Button onClick={() => {
                                    setEventAndParamEvent(Array.from(suppliedAnalyticsData?.eventData.eventNames || [])[0] || '')
                                    setEventAndParamParam('')
                                }}>
                                    Clear
                                </Button>
                                <Button color='green' onClick={() => {
                                    setEventAndParamPopOverOpen(false)
                                }}>
                                    Accept
                                </Button>
                            </Flex>
                        </FocusLock>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    }

    if (!suppliedAnalyticsData) {
        return (
            <Text>
                No data
            </Text>
        )
    }

    return (
        <VStack w="100%">
            <Flex w='100%' direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0} pt={3}>
                <Center pt={1}>
                    <Text fontWeight={500} w='100%' textAlign='left'>
                        Report Builder
                    </Text>
                </Center>
            </Flex>
            <Divider />
            <HStack w='100%' pr={2} justifyContent='space-between'>
                <Flex w='100%' direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0} pt={3}>
                    <Center pt={1} gap={3}>
                        <RangeDatepicker
                            selectedDates={range}
                            onDateChange={setRange}
                            maxDate={new Date()}
                        />
                        {//filterPopOver()}
                        }
                        {eventAndParamPopOver()}
                    </Center>
                    <Center pt={1} gap={3}>
                        <Button
                            variant="outline"
                            disabled={fetchingData || dateRangeIsTheSame(range, lastSuccessfulFetchRange)}
                            onClick={() => {
                                pressRunButton()
                            }}>
                            Run
                        </Button>
                    </Center>
                </Flex>
            </ HStack>
            <Divider />
            <Box w='100%'>
                <TableContainer>
                    {getFilteredTableData()}
                </TableContainer>
            </Box>
        </ VStack>
    )
}

export default ReportBuilder
