export interface ProcessedData {
    uniqueUsers: number;
    peakDAU: number;
    medianDAU: number;
    peakConcurrentUsers: number;
    currentConcurrentUsers: number;
    concurrentUsersPerDay: { [key: string]: number },
    uniqueSessions: number;
    totalSessionTime: number;
    averageSessionTime: number;
    medianSessionTime: number;
    medianTimePerUser: number;
    medianTimePerDay: number;
    medianTimePerUserPerDay: number;
    totalSessionTimePerDay: number;
    sessionsPerUser: number;
    statsForEachDay: { [key: string]: any },
    retentionData: { [key: string]: any },
    eventData: {
        events: Array<any>,
        eventCount: number,
        eventNames: Set<string>,
        eventParamNames: Set<string>,
        eventParamValues: Map<string, Array<string>>,
        eventCounts: Map<string, number>,
        eventUsers: Map<string, Set<string>>
    }
}

//default processed data object
const defaultProcessedData: ProcessedData = {
    uniqueUsers: 0,
    peakDAU: 0,
    medianDAU: 0,
    peakConcurrentUsers: 0,
    currentConcurrentUsers: 0,
    concurrentUsersPerDay: {},
    uniqueSessions: 0,
    totalSessionTime: 0,
    averageSessionTime: 0,
    medianSessionTime: 0,
    medianTimePerUser: 0,
    medianTimePerDay: 0,
    medianTimePerUserPerDay: 0,
    totalSessionTimePerDay: 0,
    sessionsPerUser: 0,
    statsForEachDay: {},
    retentionData: {},
    eventData: {
        events: [],
        eventCount: 0,
        eventNames: new Set(),
        eventParamNames: new Set(),
        eventParamValues: new Map(),
        eventCounts: new Map(),
        eventUsers: new Map()
    }
};

export const processData = (data: any): ProcessedData => {
    let processedData: ProcessedData = defaultProcessedData;

    if (!data || data.length === 0) {
        return processedData;
    }

    //console.log(data);

    //get number of unique users and sessions by iterating through the data
    let userIdSet = new Set();
    let sessionIdSet = new Set();
    let days = new Set();
    processedData.eventData = {
        events: [],
        eventCount: 0,
        eventNames: new Set(),
        eventParamNames: new Set(),
        eventParamValues: new Map(),
        eventCounts: new Map(),
        eventUsers: new Map()
    };
    for (let i = 0; i < data.length; i++) {
        const userHasBeenCounted = userIdSet.has(data[i].user_id);

        userIdSet.add(data[i].user_id);
        sessionIdSet.add(data[i].session_id);

        let date = new Date(data[i].timestamp / 10_000_000);
        let day = date.toDateString();
        days.add(day);

        //get event data
        if (data[i].action === "event") {
            processedData.eventData.events.push(data[i]);
            processedData.eventData.eventCount++;
            if (processedData.eventData.eventCounts.has(data[i].payload.name)) {
                processedData.eventData.eventCounts.set(data[i].payload.name, (processedData.eventData.eventCounts.get(data[i].payload.name) || 0) + 1);
            } else {
                processedData.eventData.eventCounts.set(data[i].payload.name, 1);
            }
            if (processedData.eventData.eventUsers.has(data[i].payload.name)) {
                processedData.eventData.eventUsers.get(data[i].payload.name)?.add(data[i].user_id);
            } else {
                processedData.eventData.eventUsers.set(data[i].payload.name, new Set([data[i].user_id]));
            }
            const payload = data[i].payload;
            if (payload) {
                //get event parameters that arent name
                Object.keys(payload).forEach((key) => {
                    if (key !== "name") {
                        processedData.eventData.eventParamNames.add(key);
                        if (processedData.eventData.eventParamValues.has(key)) {
                            processedData.eventData.eventParamValues.get(key)?.push(payload[key]);
                        } else {
                            processedData.eventData.eventParamValues.set(key, [payload[key]]);
                        }
                    } else {
                        processedData.eventData.eventNames.add(payload[key]);
                    }
                });
            }
        }
    }

    //console.log(processedData.eventData);

    processedData.uniqueUsers = userIdSet.size;
    processedData.uniqueSessions = sessionIdSet.size;

    let daysArray: Array<string> = Array.from(days) as Array<string>;

    //get peak concurrent users
    //concurrent users are users who are active at the same time
    //only pay attention to the first "initialize" and last "terminate" actions for each session
    let peakConcurrentUsers = 1;
    let concurrentUsers = 0;
    let sessionTimes = new Map();

    interface SessionTimeObj {
        sessionStartTime: number;
        sessionEndTime: number;
        sessionUser: string;
    }
    let sessionDateTimes: { [sessionId: string]: SessionTimeObj } = {};
    for (let day of daysArray) {
        let dayData = data.filter((d: any) => new Date(d.timestamp / 10_000_000).toDateString() === day);
        dayData.sort((a: any, b: any) => a.timestamp - b.timestamp);
        let countedUserInitializations = new Set();
        let countedSessionInitializations = new Set();

        for (let i = 0; i < dayData.length; i++) {
            if (dayData[i].action === "initialize" && !countedUserInitializations.has(dayData[i].user_id)) {
                const initializeAction = dayData[i];
                concurrentUsers++;
                countedUserInitializations.add(initializeAction.user_id);

                //find last "terminate" action for session
                const terminateAction = dayData.filter((d: any) => d.action === "terminate" && d.session_id === initializeAction.session_id).reverse()[0];
                if (terminateAction) {
                    concurrentUsers--;
                }
            }

            if (dayData[i].action === "initialize" && !countedSessionInitializations.has(dayData[i].session_id)) {
                const initializeAction = dayData[i];
                countedSessionInitializations.add(initializeAction.session_id);

                const terminateAction = dayData.filter((d: any) => d.action === "terminate" && d.session_id === initializeAction.session_id).reverse()[0];
                if (terminateAction) {
                    const sessionTime = (terminateAction.timestamp / 10_000_000) - (initializeAction.timestamp / 10_000_000);
                    const sessionUser = initializeAction.user_id;
                    if (sessionTime && sessionUser) {
                        sessionTimes.set(initializeAction.session_id, { sessionTime, sessionUser });
                    }

                    sessionDateTimes[initializeAction.session_id] = {
                        sessionStartTime: initializeAction.timestamp / 10_000_000,
                        sessionEndTime: terminateAction.timestamp / 10_000_000,
                        sessionUser: initializeAction.user_id,
                    };
                } else {
                    //if there is no "terminate" action, calculate session time using current time
                    //sessionTimes.set(initializeAction.session_id, (new Date().getTime() * 10_000_000) - initializeAction.timestamp);

                    sessionDateTimes[initializeAction.session_id] = {
                        sessionStartTime: initializeAction.timestamp / 10_000_000,
                        sessionEndTime: 0,
                        sessionUser: initializeAction.user_id
                    };
                }
            }
        }

        processedData.concurrentUsersPerDay[day] = concurrentUsers;
        concurrentUsers = 0;
    }

    let conSet = new Set();

    //find peak concurrent users using sessionDateTimes
    Object.keys(sessionDateTimes).forEach(sessionId => {
        const sessionTimeObj = sessionDateTimes[sessionId];
        if (!sessionTimeObj) {
            return;
        }
        const { sessionStartTime, sessionEndTime, sessionUser } = sessionTimeObj;
        let sessionStart = new Date(sessionStartTime);
        let sessionEnd = new Date(sessionEndTime);

        //check if sessionStart happened over a day ago
        const dayAgo: Date = new Date(Date.now() - 1000 * 60 * 60 * 24);
        if (sessionStart < dayAgo && sessionEndTime === 0) {
            return;
        }

        if (sessionEndTime === 0) {
            conSet.add(sessionUser);
        }

        //find number of sessions with overlapping times
        let overlappingSessions = 0;
        Object.keys(sessionDateTimes).forEach(otherSessionId => {
            if (sessionId !== otherSessionId) {
                const otherSessionTimeObj = sessionDateTimes[otherSessionId];
                if (!otherSessionTimeObj) {
                    return;
                }
                if (otherSessionTimeObj.sessionUser === sessionUser) {
                    return;
                }
                const { sessionStartTime: otherSessionStartTime, sessionEndTime: otherSessionEndTime } = otherSessionTimeObj;

                let otherSessionStart = new Date(otherSessionStartTime);
                let otherSessionEnd = new Date(otherSessionEndTime);

                if (otherSessionStart < dayAgo && otherSessionEndTime === 0) {
                    return;
                }

                if ((sessionStart < otherSessionEnd && sessionEnd > otherSessionStart)
                    ||
                    (otherSessionEnd.getTime() === 0 && sessionEnd.getTime() === 0)) {
                    overlappingSessions++;
                }
            }
        });

        if (overlappingSessions > peakConcurrentUsers) {
            peakConcurrentUsers = overlappingSessions;
        }
    });

    if (conSet.size > peakConcurrentUsers) {
        peakConcurrentUsers = conSet.size;
    }

    processedData.peakConcurrentUsers = peakConcurrentUsers;

    processedData.currentConcurrentUsers = conSet.size; //concurrentUsers; //initializeActions.length - terminateActions.length;
    //processedData.currentConcurrentUsers = Object.keys(sessionsData).length;

    let sessionIdArray = Array.from(sessionIdSet);

    //iterate through session set to get total session time
    let totalSessionTime = 0;
    let totalSessionTimesArray: number[] = [];
    let timePerUser: { [key: string]: number } = {};
    for (let sessionId of sessionIdArray) {
        const sessionTimeObj = sessionTimes.get(sessionId);
        if (!sessionTimeObj) {
            continue;
        }
        const { sessionTime, sessionUser } = sessionTimeObj;
        totalSessionTime += sessionTime;
        totalSessionTimesArray.push(sessionTime);

        if (timePerUser[sessionUser]) {
            timePerUser[sessionUser] += sessionTime;
        } else {
            timePerUser[sessionUser] = sessionTime;
        }
    }

    processedData.totalSessionTime = totalSessionTime;
    //get average session time
    processedData.averageSessionTime = totalSessionTime / sessionIdArray.length;
    //get median session time
    totalSessionTimesArray.sort((a, b) => a - b);
    let medianIndex = Math.floor(totalSessionTimesArray.length / 2);
    processedData.medianSessionTime = totalSessionTimesArray[medianIndex];
    //get total session time per day
    processedData.totalSessionTimePerDay = totalSessionTime / days.size;
    //get number of sessions per unique user
    processedData.sessionsPerUser = sessionIdArray.length / userIdSet.size;
    //get median time per user
    let timePerUserArray = Object.values(timePerUser);
    timePerUserArray.sort((a, b) => a - b);
    let medianTimePerUserIndex = Math.floor(timePerUserArray.length / 2);
    processedData.medianTimePerUser = timePerUserArray[medianTimePerUserIndex];

    let peakDAU = 0;

    //get stats for each day
    let statsForEachDay: { [key: string]: any } = {};
    for (let day of daysArray) {
        let dayData = data.filter((d: any) => new Date(d.timestamp / 10_000_000).toDateString() === day);
        let dayStats = {
            uniqueUsers: 0,
            timeForEachUser: new Map<string, number>(),
            uniqueSessions: 0,
            totalSessionTime: 0,
            averageSessionTime: 0,
            medianSessionTime: 0,
            sessionsPerUser: 0
        };

        let dayUserIdSet = new Set();
        let daySessionIdSet = new Set();
        for (let i = 0; i < dayData.length; i++) {
            dayUserIdSet.add(dayData[i].user_id);
            daySessionIdSet.add(dayData[i].session_id);
        }

        dayStats.uniqueUsers = dayUserIdSet.size;
        dayStats.uniqueSessions = daySessionIdSet.size;

        let daySessionIdArray = Array.from(daySessionIdSet);
        let dayTotalSessionTime = 0;
        let daySessionTimesArray: number[] = [];
        for (let sessionId of daySessionIdArray) {
            const sessionTimeObj = sessionTimes.get(sessionId);
            if (!sessionTimeObj) {
                continue;
            }
            const { sessionTime, sessionUser } = sessionTimeObj;
            dayTotalSessionTime += sessionTime;
            daySessionTimesArray.push(sessionTime);
            dayStats.timeForEachUser.set(sessionUser, (dayStats.timeForEachUser.get(sessionUser) || 0) + sessionTime);
        }

        dayTotalSessionTime = dayTotalSessionTime;
        dayStats.totalSessionTime = dayTotalSessionTime;
        dayStats.averageSessionTime = dayTotalSessionTime / daySessionIdArray.length;

        daySessionTimesArray.sort((a, b) => a - b);
        let dayMedianIndex = Math.floor(daySessionTimesArray.length / 2);
        dayStats.medianSessionTime = daySessionTimesArray[dayMedianIndex];

        dayStats.sessionsPerUser = daySessionIdArray.length / dayUserIdSet.size;

        if (dayUserIdSet.size > peakDAU) {
            peakDAU = dayUserIdSet.size;
        }

        statsForEachDay[day] = dayStats;
    }

    //get median time spent per user per day using dayStats.timeForEachUser
    let timePerUserPerDay: number[] = [];
    for (let day of daysArray) {
        let dayStats = statsForEachDay[day];
        let timeForEachUserArray: number[] = Array.from(dayStats.timeForEachUser.values());
        timeForEachUserArray.sort((a, b) => a - b);
        let medianTimePerUserPerDayIndex = Math.floor(timeForEachUserArray.length / 2);
        timePerUserPerDay.push(timeForEachUserArray[medianTimePerUserPerDayIndex]);
    }
    timePerUserPerDay.sort((a, b) => a - b);
    let medianTimePerUserPerDayIndex = Math.floor(timePerUserPerDay.length / 2);
    processedData.medianTimePerUserPerDay = timePerUserPerDay[medianTimePerUserPerDayIndex];

    processedData.peakDAU = peakDAU;
    //get median DAU
    let totalUsersArrayPerDay: number[] = [];
    for (let day of daysArray) {
        totalUsersArrayPerDay.push(statsForEachDay[day].uniqueUsers);
    }
    totalUsersArrayPerDay.sort((a, b) => a - b);
    let medianDAUIndex = Math.floor(totalUsersArrayPerDay.length / 2);
    processedData.medianDAU = totalUsersArrayPerDay[medianDAUIndex];
    processedData.statsForEachDay = statsForEachDay;

    //get median time per day
    let totalSessionTimesArrayPerDay: number[] = [];
    for (let day of daysArray) {
        totalSessionTimesArrayPerDay.push(statsForEachDay[day].totalSessionTime);
    }
    totalSessionTimesArrayPerDay.sort((a, b) => a - b);
    let medianTimePerDayIndex = Math.floor(totalSessionTimesArrayPerDay.length / 2);
    processedData.medianTimePerDay = totalSessionTimesArrayPerDay[medianTimePerDayIndex];

    // Calculate retention data
    let retentionData: { [key: number]: {} } = {};

    // Transform data to group activities by user
    let userActivities: { [userId: string]: Set<string> } = {};
    data.forEach((activity: { user_id: string; timestamp: number; }) => {
        const userId: string = activity.user_id;
        const timestamp: Date = new Date(activity.timestamp / 10_000_000);
        if (!userActivities[userId]) {
            userActivities[userId] = new Set();
        }
        //console.log(userActivities);
        userActivities[userId].add(timestamp.toDateString());
    });

    let dayCounts: number[] = Array(8).fill(0); // To store counts of active users per day, initializing days 0-7

    Object.keys(userActivities).forEach(userId => {
        let firstDay: Date = new Date();
        userActivities[userId].forEach((day: string) => {
            let dayIndex: number = Math.floor((new Date(day).getTime() - firstDay.getTime()) / (1000 * 60 * 60 * 24));
            if (dayIndex < 0) {
                firstDay = new Date(day);
                dayIndex = 0;
            }
            if (dayIndex < 8) {
                dayCounts[dayIndex]++;
            }
        });
    });

    // Calculate retention rates based on the total number of users who started (day 0 count)
    let totalUsersStarted: number = dayCounts[0];
    retentionData[0] = {
        retentionRate: 100,
        //store the total number of users as well as the total retained on this day
        totalUsers: totalUsersStarted,
        totalRetained: totalUsersStarted
    } // Day 0 is always 100% by definition
    for (let i = 1; i < 8; i++) {
        retentionData[i] = {
            retentionRate: (dayCounts[i] / totalUsersStarted) * 100,
            totalUsers: totalUsersStarted,
            totalRetained: dayCounts[i]
        }
    }

    processedData.retentionData = retentionData;

    return processedData;
}
