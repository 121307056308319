import { Button, Center, Divider, Flex, HStack, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useTheme, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'

import { AppStateContext } from '../Contexts/AppStateContext'
import Moment from 'react-moment'

const LiveActivityFeed = () => {
    const appState = useContext(AppStateContext)

    const [liveActivityData, setLiveActivityData] = useState<any[]>([])
    const theme = useTheme()

    async function fetchData() {
        await Promise.resolve(appState.getLiveAnalyticsData?.(appState.selectedApplication?.service_key || "")).then((result) => {
            setLiveActivityData(result || [])
        })
    }

    useEffect(() => {
        if (!appState.selectApplication || !appState.selectedApplication?.service_key) {
            return
        }

        fetchData().then()
    }, [appState])

    return (
        <HStack>
            <VStack p={3} w='100%' maxH='calc(100vh - 100px)'>
                <Flex w='100%' direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0}>
                    <Center pt={1}>
                        <Text fontWeight={500} w='100%' textAlign='left'>
                            Live Activity Feed
                        </Text>
                    </Center>
                    <Center pt={1}>
                        <Flex direction="row">
                            <Button variant="outline" onClick={() => fetchData()}>
                                Refresh
                            </Button>
                        </Flex>
                    </Center>
                </Flex>
                <Divider />
                <VStack w='100%' alignSelf='flex-start' mt={2} p={0} pr={5} overflowY='auto'>
                    {liveActivityData && liveActivityData.length ? (
                        <TableContainer w='100%' h='100%' overflowX="unset" overflowY="unset">
                            <Table w='100%' h='100%' variant='simple' >
                                <Thead position="sticky" bgColor={theme.__cssMap['colors.chakra-body-bg'].value} top={0} zIndex={100} shadow='lg'>
                                    <Tr>
                                        <Th>Timestamp</Th>
                                        <Th>User</Th>
                                        <Th>Action</Th>
                                        <Th>Payload</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        liveActivityData.map((activity: any) => {
                                            return (
                                                <Tr p={3} w='100%' h='100%' key={activity.timestamp}>
                                                    <Td>
                                                        <Text fontWeight={400} w='100%'>{<Moment local format='YYYY/MM/DD HH:mm:ss'>{activity.timestamp}</Moment>}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontWeight={400} w='100%'>{activity.user_id}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontWeight={400} w='100%'>{activity.action}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontWeight={400} w='100%'>{JSON.stringify(activity.payload)}</Text>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Spinner size='md' />
                    )}
                </ VStack>
            </VStack>
        </HStack>
    )
}

export default LiveActivityFeed
