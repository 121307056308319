import { Button, Card, Center, Divider, FormControl, FormHelperText, FormLabel, HStack, Input, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'

import { AppStateContext } from '../Contexts/AppStateContext'
import { AuthContext } from '../Contexts/AuthContext'

const AccountSettings = () => {
    const authContext = useContext(AuthContext)

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')

    const handleChangePassword = async () => {
        try {
            if (!oldPassword || !newPassword || !confirmNewPassword || !authContext.setNewPassword) {
                console.log('missing fields')
                return
            }

            // Check if new password is at least 8 characters long and less than 64 characters long
            if (newPassword.length < 8 || newPassword.length > 64) {
                return
            }

            if (newPassword !== confirmNewPassword) {
                return
            }

            authContext.setNewPassword(oldPassword, newPassword)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <HStack>
            <VStack p={3} w='100%'>
                <Text fontWeight={500} w='100%' textAlign='left'>Account Settings</Text>
                <Divider />
                <VStack alignSelf='flex-start' mt={2} p={0}>
                    <Card w='100%' p={3}>
                        <Text fontWeight={500} w='100%' textAlign='left'>{authContext.user?.email}</Text>
                    </ Card>
                    <Card w='100%' p={2}>
                        <HStack p={1}>
                            <Text fontWeight={500} w='100%' textAlign='left'>
                                {authContext.user?.account_id}
                            </Text>
                            <Button variant='outline' onClick={() => navigator.clipboard.writeText(authContext.user?.account_id || '')}>
                                Copy
                            </Button>
                        </HStack>
                    </ Card>
                    <Divider mt={1} mb={1} />
                    <Card w='100%' p={4} variant='outline'>
                        <FormControl isRequired>
                            <FormLabel>Current Password</FormLabel>
                            <Input value={oldPassword} type='password' autoComplete='off' readOnly onFocus={
                                (e) => {
                                    e.target.readOnly = false
                                }
                            } onChange={(e) => setOldPassword(e.target.value)} />
                            <FormLabel mt={5}>New Password</FormLabel>
                            <Input value={newPassword} type='password' autoComplete='off' readOnly onFocus={
                                (e) => {
                                    e.target.readOnly = false
                                }
                            } onChange={(e) => setNewPassword(e.target.value)} />
                            <FormHelperText>Must be at least 8 characters long.</FormHelperText>
                            <FormLabel mt={5}>Confirm New Password</FormLabel>
                            <Input value={confirmNewPassword} type='password' autoComplete='off' readOnly onFocus={
                                (e) => {
                                    e.target.readOnly = false
                                }
                            } onChange={(e) => setConfirmNewPassword(e.target.value)} />
                            <FormHelperText>Must match new password.</FormHelperText>
                            <Divider mt={5} mb={3} />
                            <Center>
                                <Button mt={2} variant='solid' type='submit' minWidth='200' onClick={handleChangePassword}>
                                    Change Password
                                </Button>
                            </Center>
                        </FormControl>
                    </Card>
                </ VStack>
            </VStack>
        </HStack>
    )
}

export default AccountSettings
