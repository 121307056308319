import { Box, Button, Card, Center, Divider, FormControl, FormHelperText, FormLabel, Input, Text, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useState } from 'react'

import { AuthContext } from '../Contexts/AuthContext';

const AuthPage = () => {
    const authContext = useContext(AuthContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [signUp, setSignUp] = useState(false)

    const handleLogin = async () => {
        try {
            if (!email || !password) {
                return;
            }

            authContext.signIn(email, password);
        } catch (e) {
            console.log(e);
        }
    };

    const handleSignup = async () => {
        try {
            if (!email || !password || !confirmPassword) {
                return;
            }

            // Check if new password is at least 8 characters long and less than 64 characters long
            if (password.length < 8 || password.length > 64) {
                return;
            }

            if (password !== confirmPassword) {
                return;
            }

            authContext.signUp(email, password, confirmPassword);
        } catch (e) {
            console.log(e);
        }
    };


    return (
        <div>
            <Center mt={6}>
                <Card p={6} variant='outline' shadow='md' maxW={300}>
                    <Text fontSize={16} fontWeight={500} align='center'>
                        {"Log in"}
                    </Text>
                    <Divider mt={5} mb={3} />
                    <FormControl isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input type='email' onChange={(e) => setEmail(e.target.value)} />
                        <FormHelperText>We'll never share your email.</FormHelperText>
                        <FormLabel mt={5}>Password</FormLabel>
                        <Input type='password' onChange={(e) => setPassword(e.target.value)} />
                        <FormHelperText>Must be at least 8 characters long.</FormHelperText>
                        {
                            signUp ?
                                <div>
                                    <FormLabel mt={5}>Confirm Password</FormLabel>
                                    <Input type='password' onChange={(e) => setConfirmPassword(e.target.value)} />
                                    <FormHelperText>Must match the password above.</FormHelperText>
                                </div>
                                :
                                null
                        }
                        <Divider mt={5} mb={3} />
                        <Center>
                            <VStack>
                                {
                                    signUp ?
                                        <Button mt={2} variant='solid' type='submit' minWidth='200' onClick={handleSignup}>
                                            Sign up
                                        </Button>
                                        :
                                        <Button mt={2} variant='solid' type='submit' minWidth='200' onClick={handleLogin}>
                                            Log in
                                        </Button>
                                }
                                <Box mt={2} cursor='pointer' onClick={() => { }//setSignUp(!signUp)
                                }>
                                    {
                                        signUp ?
                                            <Text fontSize={14} fontWeight={500} _hover={{ color: 'gray' }}>
                                                {
                                                    "Already have an account?"
                                                }
                                            </Text>
                                            :
                                            <Text fontSize={14} fontWeight={500} _hover={{ color: 'gray' }}>
                                                {
                                                    //"Don't have an account?"
                                                    ""
                                                }
                                            </Text>
                                    }
                                </Box>
                            </VStack>
                        </Center>
                    </FormControl>
                </Card>
            </Center>
        </div>
    )
}

export default AuthPage