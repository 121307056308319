import { Button, Center, Checkbox, Divider, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import * as cheerio from 'cheerio';
import axios from "axios";

interface CreateApplicationModalProps {
    onClose: () => void,
    onSave: (
        name: string, platform: string, steam_url: string | null,
        demo_date: string | null, launch_date: string | null, icon_url: string | null
    ) => void
}

const CreateApplicationModal = ({ onClose, onSave }: CreateApplicationModalProps) => {
    const [gameName, setGameName] = useState<string>('')
    const [demoDate, setDemoDate] = useState<string>('')
    const [launchDate, setLaunchDate] = useState<string>('')
    const [gameIconURL, setGameIconURL] = useState<string>('')
    const [platform, setPlatform] = useState<string>('windows')
    const [gameSteamURL, setGameSteamURL] = useState<string>('')
    const [liveCheckBox, setLiveCheckBox] = useState<boolean>(false)
    const [gettingSteamInfo, setGettingSteamInfo] = useState<boolean>(false)
    const [gotSteamInfo, setGotSteamInfo] = useState<boolean>(false)

    const getSteamAppInfoFromUrl = async (steamURL: string) => {
        setGettingSteamInfo(true)

        const baseURL = 'https://h54utawaykis6slcjzwxve2mwu0lsnmk.lambda-url.us-east-2.on.aws/'
        const response = await axios.post(baseURL,
            {
                url: steamURL
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )

        const { date, icon, name } = response.data

        console.log(response.data)

        setDemoDate(date)
        setLaunchDate(date)
        setGameIconURL(icon)
        setGameName(name)
        setGotSteamInfo(true)
        setGettingSteamInfo(false)
    }

    return (
        <>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>New Application</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Import from Steam URL</FormLabel>
                        <Input value={gameSteamURL} onChange={(e) => setGameSteamURL(e.target.value)} placeholder='https://store.steampowered.com/app/730/CounterStrike_2/' />
                    </FormControl>

                    <VStack justify='flex-start' align='flex-start'>
                        <Button mt={2} onClick={() => getSteamAppInfoFromUrl(gameSteamURL)}>Get Steam App Info</Button>
                        <Checkbox size='md' mt={4} isChecked={liveCheckBox} onChange={(e) => setLiveCheckBox(e.target.checked)}>
                            My game isn't live yet
                        </Checkbox>
                    </VStack>

                    {
                        ((liveCheckBox || gotSteamInfo) && !gettingSteamInfo) ? (
                            <>
                                <FormControl mt={4}>
                                    <FormLabel>Name</FormLabel>
                                    <Input value={gameName} onChange={(e) => setGameName(e.target.value)} placeholder='My Application' />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Demo Date</FormLabel>
                                    <Input value={demoDate} onChange={(e) => setDemoDate(e.target.value)} placeholder='1/1/2026' />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Launch Date</FormLabel>
                                    <Input value={launchDate} onChange={(e) => setLaunchDate(e.target.value)} placeholder='1/1/2027' />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Game Icon URL</FormLabel>
                                    <Input value={gameIconURL} onChange={(e) => setGameIconURL(e.target.value)} placeholder='https://cdn.cloudflare.steamstatic.com/steam/apps/730/capsule_184x69.jpg' />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Platform</FormLabel>
                                    <Select value={platform} onChange={(e) => setPlatform(e.target.value)}>
                                        <option value="windows">Windows</option>
                                        <option value="macos">Mac</option>
                                        <option value="linux">Linux</option>
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            gettingSteamInfo ? (
                                <Center>
                                    <Spinner mt={10} />
                                </Center>
                            ) : (
                                <></>
                            )
                        )
                    }
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => { onSave(gameName, platform, gameSteamURL, demoDate, launchDate, gameIconURL); onClose(); }} colorScheme='blue' mr={3}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </>
    )
}

export default CreateApplicationModal