import { Box, Button, Center, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import React, { useContext } from "react"

import { ColorModeSwitcher } from "../ColorModeSwitcher"
import { AuthContext } from "../Contexts/AuthContext"

const TopBar = () => {
    const navigate = useNavigate()
    const authContext = useContext(AuthContext)

    return (
        <div style={{
            //position: 'fixed',
            //zIndex: 1,
            //width: '100%',
        }}>
            <Flex direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0}>
                <Box onClick={() => navigate('/')} cursor="pointer">
                    <Text fontWeight={500} fontSize={30}>
                        {"playtime"}
                    </Text>
                </Box>
                <Center pt={1}>
                    <Flex direction="row">
                        <Center pr={1}>
                            {
                                authContext.isLoggedIn() && authContext.user && authContext.user.email ?
                                    (
                                        <Menu>
                                            <MenuButton as={Button} variant="outline" color="current" aria-label="Home">
                                                <Text fontWeight={300} fontSize={18}>
                                                    {authContext.user.email}
                                                </Text>
                                            </MenuButton>
                                            <MenuList p={2}>
                                                <MenuItem onClick={() => navigate('/dashboard/account-settings')}>Account Settings</MenuItem>
                                                <Divider mt={2} mb={2} />
                                                <MenuItem onClick={() => authContext.signOut()}>Log out</MenuItem>
                                            </ MenuList>
                                        </Menu>
                                    ) : null
                            }
                        </Center>
                        {
                            !authContext.isLoggedIn() &&
                            <Button variant="outline" color="current" aria-label="Home" onClick={() => navigate('/login')}>
                                Log in
                            </Button>
                        }
                        <ColorModeSwitcher />
                    </Flex>
                </Center>
            </Flex>
            <Divider />
        </div>
    )
}

export default TopBar