import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"

import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const data = [
    { name: 'Awesome', uv: 8000, amt: 9999 },
    { name: 'PC', uv: 9000, amt: 9999 },
    { name: 'Analytics', uv: 8000, amt: 9999 },
    { name: 'Solution', uv: 9000, amt: 9999 },
];

const HomePage = () => {
    return (
        <div>
            <Flex direction="column" justifyContent="center" alignItems="center" pt={5}>
                <Box>
                    <LineChart width={600} height={300} data={data}>
                        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="name" />
                        <YAxis dataKey="amt" />
                    </LineChart>
                </Box>
                <Box>
                    <Text fontSize={30} fontWeight={500} align='center'>
                        {"Welcome to playtime!"}
                    </Text>
                    <Text fontSize={20} fontWeight={400} align='center'>
                        {"Please log in to continue."}
                    </Text>
                </Box>
            </Flex>
        </div>
    )
}

export default HomePage