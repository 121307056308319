import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseApiUrl } from "../Utilities/constants";

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface User {
    email: string;
    account_id: string;
    token: string;
    expires: number;
}

export interface IAuth {
    authStatus?: AuthStatus;
    user?: User | null;
    signIn?: any;
    signOut?: any;
    signUp?: any;
    isLoggedIn?: any;
    setNewPassword?: (oldPassword: string, newPassword: string) => void;
}

const defaultState: IAuth = {
    authStatus: AuthStatus.Loading,
};

type Props = {
    children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext);
    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext);
    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const userStorageKey = "session_token";

const AuthProvider = ({ children }: Props) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const [user, setUser] = useState<any | null>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            //check if session token is still valid (not expired)
            if (user && user.token && user.expires && user.token.length > 0) {
                if (user.expires < Date.now() / 1000) {
                    //session expired, log out
                    signOut();
                    //console.log("session expired");
                } else {
                    //console.log("session still valid");
                    //console.log(user.expires - Date.now() / 1000);
                }
            }
        }, 10 * 1000);

        return () => clearInterval(interval);
    }, [user]);

    useEffect(() => {
        setAuthStatus(AuthStatus.Loading);

        async function getStoredUser() {
            try {
                //attempt to get user from local storage
                let localStorageUser = localStorage.getItem(userStorageKey);
                if (localStorageUser) {
                    let parsedUser = JSON.parse(localStorageUser);
                    if (parsedUser.token && parsedUser.expires && parsedUser.token.length > 0 && parsedUser.expires > Date.now() / 1000) {
                        setUser(JSON.parse(localStorageUser));
                        setAuthStatus(AuthStatus.SignedIn);
                        return;
                    } else {
                        signOut();
                        return;
                    }
                }

                setAuthStatus(AuthStatus.SignedOut);
            } catch (e) {
                setAuthStatus(AuthStatus.SignedOut);
            }
        }

        getStoredUser().then();
    }, [setAuthStatus, authStatus]);

    async function signIn(email: string, password: string) {
        clearLocalStorage();

        try {
            setAuthStatus(AuthStatus.Loading);

            //attempt to login
            let response = await axios.post(`${baseApiUrl}/dashboard/login`, {
                email: email,
                password: password,
            });

            if (!response || !response.data) {
                console.log("error logging in");
                setAuthStatus(AuthStatus.SignedOut);
                return;
            }

            //store user in local storage
            localStorage.setItem(userStorageKey, JSON.stringify(response.data));

            setAuthStatus(AuthStatus.SignedIn);
        } catch (e) {
            console.log(e);
            setAuthStatus(AuthStatus.SignedOut);
        }
    }

    function clearLocalStorage() {
        try {
            let currentColorMode = localStorage.getItem("chakra-ui-color-mode");
            localStorage.clear();
            localStorage.setItem("chakra-ui-color-mode", currentColorMode || "light");
        } catch (e) {
            console.log(e);
        }
    }

    const signUp = async (email: string, password: string, confirmPassword: string) => {
        clearLocalStorage();

        try {
            if (!email || !password || !confirmPassword) {
                return;
            }

            if (password !== confirmPassword) {
                return;
            }

            const response = await axios.post(`${baseApiUrl}/dashboard/create-account`, {
                email: email,
                password: password
            });

            if (!response.data) {
                console.log('error creating account');
                return;
            }

            signIn(email, password);
        } catch (e) {
            console.log(e);
        }
    };

    function signOut() {
        //clear local storage
        clearLocalStorage();

        setAuthStatus(AuthStatus.SignedOut);
    }

    async function setNewPassword(password: string, newPassword: string) {
        try {
            if (!user || !user.account_id || !user.token || !password || !newPassword) {
                return;
            }

            let response = await axios.post(`${baseApiUrl}/dashboard/change-password`, {
                password: password,
                new_password: newPassword
            },
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                }
            );

            if (!response || !response.data) {
                console.log("error logging in");
                setAuthStatus(AuthStatus.SignedOut);
                return;
            }

            //store user in local storage
            localStorage.setItem(userStorageKey, JSON.stringify(response.data));

            //set user
            setUser(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    function isLoggedIn() {
        return authStatus === AuthStatus.SignedIn;
    }

    const state: IAuth = {
        authStatus,
        user,
        signIn,
        signUp,
        signOut,
        isLoggedIn,
        setNewPassword,
    };

    if (authStatus === AuthStatus.Loading) {
        return null;
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
