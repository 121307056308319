import { Box, Button, Card, Center, Checkbox, Divider, Flex, FocusLock, HStack, Popover, PopoverBody, PopoverContent, PopoverTrigger, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, Wrap } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { processData, ProcessedData } from '../Utilities/dataProcessing'

interface EventExplorerProps {
    analyticsData: ProcessedData | undefined
}

const EventExplorer = ({ analyticsData }: EventExplorerProps) => {
    const [reportFilterMenuState, setReportFilterMenuState] = useState('Event')

    interface FilterState {
        ['Event']: string[] | null,
        ['Install Date']: string[],
        ['App Version']: string[],
    }
    const defaultFilterState = {
        ['Event']: analyticsData && analyticsData.eventData ? Array.from(analyticsData?.eventData.eventNames) : null,
        ['Install Date']: [],
        ['App Version']: [],
    }
    const [reportFilterState, setReportFilterState] = useState<FilterState>(defaultFilterState)

    useEffect(() => {
        if (analyticsData) {
            if (reportFilterState['Event'] === null) {
                setReportFilterState(defaultFilterState)
            }
        }
    }, [defaultFilterState])

    const getFilterButtons = () => {
        switch (reportFilterMenuState) {
            case 'Event':
                return (
                    Array.from(analyticsData?.eventData.eventNames || []).map((key: string) => {
                        return (
                            <Checkbox isChecked={
                                reportFilterState['Event']?.includes(key)
                            } key={key} onChange={(e) => {
                                if (e.target.checked) {
                                    setReportFilterState({
                                        ...reportFilterState,
                                        'Event': [...reportFilterState['Event'] || [], key]
                                    })
                                } else {
                                    setReportFilterState({
                                        ...reportFilterState,
                                        'Event': (reportFilterState['Event'] || []).filter((value) => value !== key)
                                    })
                                }
                            }}>
                                {key}
                            </Checkbox>
                        )
                    })
                )
            case 'Install Date':
                return (
                    null
                )
            case 'App Version':
                return (
                    null
                )
            default:
                return (
                    null
                )
        }
    }

    const getFilteredTableData = () => {
        let filteredData = new Map<string, Map<string, number>>()
        Object.keys(reportFilterState).forEach((key) => {
            switch (key) {
                case 'Event':
                    const events = reportFilterState['Event']
                    if (events === null) {
                        break;
                    }

                    //sort events by count
                    events.sort((a, b) => {
                        const aCount = analyticsData?.eventData.eventCounts.get(a) || 0
                        const bCount = analyticsData?.eventData.eventCounts.get(b) || 0
                        return bCount - aCount
                    })
                    events.forEach((event) => {
                        if (analyticsData && analyticsData.eventData && analyticsData.eventData.eventNames.has(event)) {
                            const eventCount = analyticsData.eventData.eventCounts.get(event) || 0;
                            const userCount = analyticsData.eventData.eventUsers.get(event)?.size || 0;

                            filteredData.set(event, new Map([
                                ['eventCount', eventCount],
                                ['userCount', userCount]
                            ]));
                        }
                    })
                    break
                case 'Install Date':
                    break
                case 'App Version':
                    break
                default:
                    break
            }
        })

        return (
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Event</Th>
                        <Th>Count</Th>
                        <Th>Users</Th>
                        <Th>Per User</Th>
                        <Th>Per Engaged</Th>
                        <Th>Engagement</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        Array.from(filteredData?.keys() || []).map((eventName: string) => {
                            const eventMap = filteredData?.get(eventName);
                            const eventCount = eventMap?.get('eventCount') || 0;
                            const userCount = eventMap?.get('userCount') || 0;
                            return (
                                <Tr key={eventName}>
                                    <Td>{eventName}</Td>
                                    <Td>{eventCount}</Td>
                                    <Td>{userCount}</Td>
                                    <Td>{Math.round((eventCount / (analyticsData?.uniqueUsers || 1)) * 100) / 100}</Td>
                                    <Td>{Math.round((eventCount / (userCount || 1)) * 100) / 100}</Td>
                                    <Td>{Math.round(((userCount / (analyticsData?.uniqueUsers || 1)) * 100) * 100) / 100}%</Td>
                                </Tr>
                            );
                        })
                    }
                </Tbody>
            </Table>
        )
    }

    return (
        <VStack w="100%">
            <Flex w='100%' direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0} pt={3}>
                <Center pt={1}>
                    <Text fontWeight={500} w='100%' textAlign='left'>
                        Event Explorer
                    </Text>
                </Center>
            </Flex>
            <Divider />
            <HStack w='100%' pr={2} justifyContent='space-between'>
                <Flex w='100%' direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0} pt={3}>
                    <Center pt={1}>
                        <Popover
                            placement='top-start'
                            offset={[0, 5]}
                        //isOpen={popOverOpen}
                        >
                            <PopoverTrigger>
                                <Button
                                    variant={'outline'}
                                    marginRight="5px"
                                    zIndex={1}
                                    type="button"
                                    padding={'8px'}
                                    onClick={() => {
                                        //setPopOverOpen(!popOverOpen)
                                    }}
                                >
                                    Filters
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                width="100%"
                            >
                                <PopoverBody shadow="lg" p={4}>
                                    <FocusLock>
                                        <Flex height="100%">
                                            <Flex
                                                gap={2}
                                                direction='column'
                                                height="100%"
                                                mr={2}
                                                padding="0.5rem 0.75rem"
                                                justifyContent='flex-start'
                                                justify='flex-start'
                                                alignContent='flex-start'
                                            >
                                                <Button onClick={() => {
                                                    setReportFilterMenuState('Event')
                                                }}>
                                                    Event
                                                </Button>
                                                {/*<Button onClick={() => {
                                                            setReportFilterMenuState('Install Date')
                                                        }}>
                                                            Install Date
                                                        </Button>
                                                        <Button onClick={() => {
                                                            setReportFilterMenuState('App Version')
                                                        }}>
                                                            App Version
                                                        </Button>*/}
                                            </Flex>
                                            <Flex direction='column'>
                                                <Card p={4}>
                                                    <Text mb={2}>
                                                        {reportFilterMenuState}
                                                    </Text>
                                                    <Divider />
                                                    <SimpleGrid columns={4} spacing={4} p={3}>
                                                        {getFilterButtons()}
                                                    </SimpleGrid>
                                                </Card>
                                            </Flex>
                                        </Flex>
                                        <Divider mb={2} mt={8} />
                                        <Flex gap={2} direction='row' justifyContent='flex-end'>
                                            <Button onClick={() => {
                                                setReportFilterState(defaultFilterState)
                                            }}>
                                                Clear
                                            </Button>
                                            {/*
                                            <Button color='green' onClick={() => {
                                                setPopOverOpen(false)
                                            }}>
                                                Accept
                                            </Button>
                                            */}
                                        </Flex>
                                    </FocusLock>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Center>
                </Flex>
            </ HStack>
            <Divider />
            <Box w='100%'>
                <TableContainer>
                    {getFilteredTableData()}
                </TableContainer>
            </Box>
        </ VStack>
    )
}

export default EventExplorer
