import { Button, Center, Input, Menu, MenuButton, MenuItem, MenuList, Modal, Spinner, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import { MdViewAgenda } from "react-icons/md";
import { AppStateContext } from '../Contexts/AppStateContext';
import CreateApplicationModal from './CreateApplicationModal';
import { AuthContext } from '../Contexts/AuthContext';

const ApplicationSearchMenu = () => {
    const appState = useContext(AppStateContext)
    const authContext = useContext(AuthContext)

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [creatingApplication, setCreatingApplication] = useState<boolean>(false)
    let filteredApplicationCount = 10

    useEffect(() => {
        //console.log(appState)
    }, [appState])

    const getApplicationMenuItems = (applications: []) => {
        //filter applications by search query
        let filteredApplications: any[] = applications.filter((application: any) => {
            return application.name.toLowerCase().includes(searchQuery.toLowerCase())
        })

        let refinedApplications: any[] = filteredApplications
            //sort by name, and then service_key if names are the same
            .sort((a: any, b: any) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                if (a.service_key < b.service_key) {
                    return -1
                }
                if (a.service_key > b.service_key) {
                    return 1
                }
                return 0
            })

        if (refinedApplications.length === 0) {
            return null;
        }

        filteredApplicationCount = refinedApplications.length

        return refinedApplications.slice(0, 10).map((application: any) => {
            return (
                <MenuItem key={application.service_key} onClick={() => appState.selectApplication(application)}>
                    {application.name}
                </MenuItem>
            )
        })
    }

    return (
        <Menu matchWidth onClose={() => { setSearchQuery("") }}>
            <Modal isOpen={creatingApplication} onClose={() => setCreatingApplication(false)}>
                <CreateApplicationModal onClose={() => setCreatingApplication(false)} onSave={(n, p, s, d, l, i) => appState.createApplication && appState.createApplication(n, p, s, d, l, i)} />
            </Modal>
            <MenuButton as={Button} variant='ghost' rightIcon={<MdViewAgenda size={20} />} width='100%' textAlign='left'>
                {
                    appState.selectedApplication ?
                        appState.selectedApplication.name
                        :
                        "Select Application..."
                }
            </MenuButton>
            <MenuList>
                {
                    appState.applications ?
                        <VStack pr={2} pl={2}>
                            <Input placeholder="Search applications" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            <MenuItem onClick={() => {
                                setCreatingApplication(true)
                            }}>
                                <Text fontWeight={500}>
                                    Create New Application
                                </Text>
                            </MenuItem>
                            {
                                getApplicationMenuItems(appState.applications)
                            }
                            {
                                filteredApplicationCount > 10 ?
                                    <MenuItem isDisabled>
                                        {`(+${filteredApplicationCount - 10} more, refine search...)`}
                                    </MenuItem>
                                    :
                                    ""
                            }
                        </VStack>
                        :
                        <Center>
                            <Spinner size='md' />
                        </Center>
                }
            </MenuList>
        </Menu>
    )
}

export default ApplicationSearchMenu