import { Accordion, AccordionButton, Box, Button, Card, Center, Checkbox, Divider, Flex, FocusLock, HStack, Input, Menu, MenuButton, MenuItem, MenuItemOption, MenuList, MenuOptionGroup, Popover, PopoverAnchor, PopoverBody, PopoverContent, PopoverTrigger, SimpleGrid, Spinner, Stat, StatLabel, StatNumber, Switch, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, Wrap } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'

import { RangeDatepicker } from '../Components/chakra-dayzed-datepicker/src/index'

import EventExplorer from './EventExplorer'
import ReportBuilder from './ReportBuilder'
import { AppStateContext, OverviewData } from '../Contexts/AppStateContext'
import { processData, ProcessedData } from '../Utilities/dataProcessing'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

const AnalyticsOverview = () => {
    const appState = useContext(AppStateContext)

    //const [analyticsData, setAnalyticsData] = useState<ProcessedData>()
    const [overviewData, setOverviewData] = useState<OverviewData>()
    const [range, setRange] = useState<Date[]>([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()])
    const [fetchingData, setFetchingData] = useState<boolean>(false)
    const [lastSuccessfulFetchRange, setLastSuccessfulFetchRange] = useState<Date[]>([new Date(), new Date()])

    const dateRangeIsTheSame = (a: Date[], b: Date[]) => {
        return a[0].getTime() === b[0].getTime() && a[1].getTime() === b[1].getTime()
    }

    async function fetchData() {
        if (fetchingData) {
            console.log('Already fetching data')
            return
        }

        if (dateRangeIsTheSame(range, lastSuccessfulFetchRange) &&
            appState.storedOverviewData &&
            (appState.selectedApplication?.service_key || '') in appState.storedOverviewData) {
            console.log('Already fetched data for this range')
            return
        }

        setFetchingData(true)
        try {
            /*await Promise.resolve(appState.getAllAnalyticsData?.(
                appState.selectedApplication?.service_key || "",
                range[0]?.getTime(),
                range[1]?.getTime() + 86_400_000 // add a day to the end date
            )).then((result) => {
                if (result) {
                    setLastSuccessfulFetchRange(range)
                }

                setAnalyticsData(processData(result || []))
                setFetchingData(false)
            })*/
            await Promise.resolve(appState.getOverviewData?.(
                appState.selectedApplication?.service_key || "",
                range[0]?.getTime(),
                range[1]?.getTime() + 86_400_000 // add a day to the end date
            )).then((result) => {
                if (result) {
                    setLastSuccessfulFetchRange(range)
                }

                setOverviewData(result || undefined)
                setFetchingData(false)
            })
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (!appState.selectApplication || !appState.selectedApplication?.service_key) {
            return
        }

        if (appState.storedOverviewData && appState.selectedApplication?.service_key in appState.storedOverviewData) {
            //const newData = processData(appState.storedAnalyticsData[appState.selectedApplication?.service_key] || []);
            //setAnalyticsData(newData)
            if (appState.storedOverviewData) {
                const newData = appState.storedOverviewData[appState.selectedApplication?.service_key]
                setOverviewData(newData)

                const dateRangeFromData = newData?.date_range
                //get min and max dates from data
                const minDate = dateRangeFromData ? new Date(dateRangeFromData.start_date) : new Date()
                const maxDate = dateRangeFromData ? new Date(dateRangeFromData.end_date) : new Date()
                setRange([minDate, maxDate])

                return
            }
        }

        fetchData().then()
    }, [appState, fetchingData])

    const getDAUChartData = () => {
        let data = []
        let i = 0
        for (let key in overviewData?.per_day_metrics) {
            data.push({
                name: overviewData?.per_day_metrics[i].session_date,
                uv: overviewData?.per_day_metrics[i].unique_users,
            })

            i++
        }
        return data
    }

    const getSessionChartData = () => {
        let data = []
        let lastDaySessionCount = -1
        let i = 0
        for (let key in overviewData?.per_day_metrics) {
            let sessions = overviewData?.per_day_metrics[i].unique_sessions || 0
            if (lastDaySessionCount !== -1 && sessions > lastDaySessionCount * 10) {
                //sessions = lastDaySessionCount
            }
            data.push({
                name: overviewData?.per_day_metrics[i].session_date,
                uv: sessions,
            })
            lastDaySessionCount = sessions

            i++
        }
        return data
    }

    /*const getSessionLengthChartData = () => {
        let data = []
        for (let key in analyticsData?.statsForEachDay) {
            data.push({
                name: key,
                uv: analyticsData?.statsForEachDay[key].totalSessionTime,
            })
        }
        return data
    }

    const getAverageSessionLengthChartData = () => {
        let data = []
        for (let key in analyticsData?.statsForEachDay) {
            data.push({
                name: key,
                uv: analyticsData?.statsForEachDay[key].averageSessionTime / 1000,
            })
        }
        return data
    }*/

    const getMedianSessionLengthChartData = () => {
        let data = []
        const medianSessionTime = overviewData?.median_session_time || 0
        let i = 0
        for (let key in overviewData?.per_day_metrics) {
            let sessionLength = overviewData?.per_day_metrics[i].median_session_length || 0
            if (sessionLength > medianSessionTime * 10) {
                //sessionLength = medianSessionTime * 10
            }
            data.push({
                name: overviewData?.per_day_metrics[i].session_date,
                uv: sessionLength / 1000,
            })

            i++
        }
        return data
    }

    const getRetentionPercChartData = () => {
        let data = []
        let i = 0
        data.push({
            name: `Day 0`,
            uv: 100,
        })
        for (let key in overviewData?.retention_days) {
            data.push({
                name: `Day ${i + 1}`,
                uv: (overviewData?.retention_days[i].retained_users || 0) / (overviewData?.retention_days[i].total_new_users || 1) * 100,
            })

            i++

            if (i >= 7) {
                break
            }
        }
        return data
    }

    const getRetentionNumChartData = () => {
        let data = []
        let i = 0
        data.push({
            name: `Day 0`,
            uv: overviewData?.retention_days
                ? overviewData?.retention_days[0].total_new_users
                : 0,
            pv: overviewData?.retention_days
                ? overviewData?.retention_days[0].total_new_users
                : 0,
        })
        for (let key in overviewData?.retention_days) {
            data.push({
                name: `Day ${i + 1}`,
                uv: overviewData?.retention_days[i].retained_users,
                pv: overviewData?.retention_days[i].total_new_users,
            })

            i++

            if (i >= 7) {
                break
            }
        }
        return data
    }

    const formatTime = (time: number) => {
        let timeInSeconds = Math.round(time / 1000)
        if (timeInSeconds < 60) {
            return `${timeInSeconds} sec`
        }

        let hours = Math.floor(timeInSeconds / 3600)
        let minutes = Math.floor(timeInSeconds / 60) % 60
        let seconds = timeInSeconds % 60
        let timeString = ''
        if (hours > 0) {
            timeString += `${hours} hr `
        }
        if (minutes > 0) {
            timeString += `${minutes} min `
        }
        if (seconds > 0) {
            timeString += `${seconds} sec`
        }
        return timeString
    }

    const getTopStuff = () => {
        return (
            <Flex maxH='calc(100vh - 100px)' w='100%' direction="row" justifyContent="space-between" pl={5} pr={2} pb={3} mt={0}>
                <Center pt={1}>
                    <Text fontWeight={500} w='100%' textAlign='left'>
                        Analytics Overview
                    </Text>
                </Center>
                <Center pt={1}>
                    <Flex gap={2} direction="row">
                        <Menu>
                            <RangeDatepicker
                                selectedDates={range}
                                onDateChange={setRange}
                                maxDate={new Date()}
                            />
                        </Menu>
                        <Button variant="outline"
                            onClick={() => fetchData()}>
                            Refresh
                        </Button>
                    </Flex>
                </Center>
            </Flex>
        )
    }

    /*if (fetchingData) {
        return (
            <HStack maxH='calc(100vh - 100px)'>
                <VStack p={3} w='100%' maxH='calc(100vh - 100px)'>
                    {getTopStuff()}
                    <Spinner />
                </VStack>
            </HStack>
        )
    }

    if (!analyticsData) {
        return (
            <HStack maxH='calc(100vh - 100px)'>
                <VStack p={3} w='100%' maxH='calc(100vh - 100px)'>
                    {getTopStuff()}
                    <Text>No data available!</Text>
                </VStack>
            </HStack>
        )
    }*/

    return (
        <HStack maxH='calc(100vh - 100px)'>
            <VStack p={3} w='100%' maxH='calc(100vh - 100px)'>
                {getTopStuff()}
                <Divider />
                <Wrap maxH='calc(100vh - 100px)' w='100%' alignSelf='flex-start' mt={2} p={0} overflowY='scroll'>
                    <VStack w='100%' pr={2} justifyContent='space-between'>
                        <HStack w='100%' pr={2} justifyContent='space-between'>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Concurrent (Live)</StatLabel>
                                    <StatNumber>{overviewData?.current_concurrent_sessions}</StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Peak Concurrent</StatLabel>
                                    <StatNumber>{overviewData?.max_concurrent_sessions}</StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Unique Users</StatLabel>
                                    <StatNumber>{overviewData?.unique_user_count}</StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Peak DAU</StatLabel>
                                    <StatNumber>{overviewData?.peak_daily_active_users}</StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Total Sessions</StatLabel>
                                    <StatNumber>{overviewData?.unique_session_count}</StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Sessions Per User</StatLabel>
                                    <StatNumber>{overviewData?.unique_session_count && overviewData?.unique_user_count ?
                                        Math.round((overviewData?.unique_session_count / overviewData?.unique_user_count) * 100) / 100 : 0}
                                    </StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Day 1 Retention</StatLabel>
                                    <StatNumber>{overviewData?.retention_days ?
                                        Math.round(((overviewData?.retention_days[0]?.retained_users / overviewData?.retention_days[0]?.total_new_users) * 100) * 100) / 100 : 0}%
                                    </StatNumber>
                                </Stat>
                            </Card>
                        </ HStack>
                        <HStack w='100%' pr={2} justifyContent='space-between'>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Total Time Spent</StatLabel>
                                    <StatNumber>
                                        {formatTime(((overviewData?.total_time_spent_all_users || 0)) || 0)}
                                    </StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Md. Session Time</StatLabel>
                                    <StatNumber>
                                        {formatTime(overviewData?.median_session_time || 0)}
                                    </StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Md. Time Per User Per Day</StatLabel>
                                    <StatNumber>
                                        {formatTime(overviewData?.median_time_per_user_per_day || 0)}
                                    </StatNumber>
                                </Stat>
                            </Card>
                            <Card p={3} w='100%' h='100%'>
                                <Stat>
                                    <StatLabel>Md. Time Per User</StatLabel>
                                    <StatNumber>
                                        {formatTime(overviewData?.median_total_time_per_user || 0)}
                                    </StatNumber>
                                </Stat>
                            </Card>
                        </ HStack>
                        <Divider mt={2} mb={1} />
                        <HStack w='100%' pr={2} justifyContent='space-between'>
                            <Box w='100%'>
                                <Text align='center' fontWeight={400} w='100%'>Unique Users</Text>
                                <ResponsiveContainer width='100%' height={200}>
                                    <LineChart
                                        width={520}
                                        height={300}
                                        data={getDAUChartData()}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ color: 'black' }} />
                                        <Line name='Unique Users' type="monotone" dataKey="uv" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                            <Box w='100%'>
                                <Text align='center' fontWeight={400} w='100%'>Unique Sessions</Text>
                                <ResponsiveContainer width='100%' height={200}>
                                    <LineChart
                                        width={520}
                                        height={300}
                                        data={getSessionChartData()}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ color: 'black' }} />
                                        <Line name='Unique Sessions' type="monotone" dataKey="uv" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                            <Box w='100%'>
                                <Text align='center' fontWeight={400} w='100%'>Median Session Length</Text>
                                <ResponsiveContainer width='100%' height={200}>
                                    <LineChart
                                        width={520}
                                        height={300}
                                        data={getMedianSessionLengthChartData()}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ color: 'black' }} />
                                        <Line name='Md. Session Length' type="monotone" dataKey="uv" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </ HStack>
                        <HStack w='100%' pr={2} justifyContent='space-between'>
                            <Box w='100%'>
                                <Text align='center' fontWeight={400} w='100%'>Retention %</Text>
                                <ResponsiveContainer width='100%' height={300}>
                                    <LineChart
                                        width={520}
                                        height={300}
                                        data={getRetentionPercChartData()}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ color: 'black' }} />
                                        <Line name='Retention' type="monotone" dataKey="uv" stroke="#82ca9d" />
                                        <Line name='Total Retained' type="monotone" dataKey="pv" stroke="#8884d8" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                            <Box w='100%'>
                                <Text align='center' fontWeight={400} w='100%'>Retention #</Text>
                                <ResponsiveContainer width='100%' height={300}>
                                    <LineChart
                                        width={520}
                                        height={300}
                                        data={getRetentionNumChartData()}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ color: 'black' }} />
                                        <Line name='Retained' type="monotone" dataKey="uv" stroke="#82ca9d" />
                                        <Line name='User Pool' type="monotone" dataKey="pv" stroke="#8884d8" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </ HStack>
                        <Divider />
                        <EventExplorer analyticsData={undefined} />
                        <Divider mt={10} />
                        <ReportBuilder suppliedAnalyticsData={undefined} />
                    </VStack>
                </Wrap>
            </VStack>
        </HStack>
    )
}

export default AnalyticsOverview
